// WARNING this file is imported both from frontend and backend
// FOR CONSTS ONLY.

export const ApiVersion = 'v16';

export const CustomerType = {
  OWNER: 'owner',
  KEEPER: 'keeper',
  DRIVER: 'driver',
  TEMPORARY_OWNER: 'temporary_owner',
  NOTIFICATION_OF_TRANSFER: 'notification_of_transfer',
};

export const PaymentState = {
  UNLINKED: 'unlinked',
  PARTIALLY_LINKED: 'partially_linked',
  LINKED: 'linked',
  EXPORTED: 'exported',
};

export const PaymentStateOrder = [
  PaymentState.UNLINKED,
  PaymentState.PARTIALLY_LINKED,
  PaymentState.LINKED,
  PaymentState.EXPORTED,
];

export const ReportState = {
  ACTIVE: 'active', // uusi
  EXPORTED: 'exported', // viety Helgaan
  EXPORTED_INVALID: 'exported_invalid', // viety Helgaan mitätöitynä
  PAYMENT_DEMAND: 'demand', // maksuvaatimus
  FINISHED: 'finished', // suljettu
  RECOVERY: 'recovery', // ulosotossa
  DEBT_RESTRUCTURING: 'debt', // velkajärjestely
  HOPELESS: 'hopeless', // toivoton
  INVALID: 'invalid', // huomautus, tai turha
  PASSIVE: 'passive', // passiivinen
  WARNING: 'warning', // huomautus
};

export const ComputedReportState = {
  UNCLEAR: 'unclear',
};

export const ReportStateOrder = [
  ReportState.ACTIVE,
  ReportState.INVALID,
  ReportState.PAYMENT_DEMAND,
  ReportState.RECOVERY,
  ReportState.DEBT_RESTRUCTURING,
  ReportState.PASSIVE,
  ReportState.HOPELESS,
  ReportState.FINISHED,
  ReportState.WARNING,
  ReportState.EXPORTED,
  ReportState.EXPORTED_INVALID,
];

export const ReportStateFilterOrder = [
  ReportState.ACTIVE,
  ReportState.INVALID,
  ReportState.PAYMENT_DEMAND,
  ReportState.RECOVERY,
  ReportState.DEBT_RESTRUCTURING,
  ReportState.PASSIVE,
  ComputedReportState.UNCLEAR,
  ReportState.HOPELESS,
  ReportState.FINISHED,
  ReportState.WARNING,
  ReportState.EXPORTED,
  ReportState.EXPORTED_INVALID,
];

export const PermanentReportStates = [
  ReportState.FINISHED,
  ReportState.EXPORTED,
  ReportState.INVALID,
  ReportState.EXPORTED_INVALID,
  ReportState.WARNING,
];

export const ClaimState = {
  OPEN: 'open',
  PROPOSAL: 'proposal',
  DECISION: 'decision',
  IN_COURT: 'in_court',
  COURT_DECISION: 'court_decision',
};

export const ClaimStateOrder = [
  ClaimState.OPEN,
  ClaimState.PROPOSAL,
  ClaimState.DECISION,
  ClaimState.IN_COURT,
  ClaimState.COURT_DECISION,
];

export const PaymentType = {
  REGISTER_MANUAL: 'register_manual', // Kassasta manuaalisesti
  BANK_IMPORT: 'bank_import', // Pankista konekielisenä
  BANK_MANUAL: 'bank_manual', // Pankista manuaalisesti
  RETURN_TO_ACCOUNT: 'return_to_account', // Palautus tilille
  ARTIFICIAL_ADJUSTMENT: 'artificial_adjustment', // Keinotekoinen jäämän tase
  RETURN_AS_MONEY_ORDER: 'return_as_money_order', // Palautus maksuosoituksena
  AMOUNT_CORRECTION: 'amount_correction', // Maksetun summan korjaus
  RECOVERY_MANUAL: 'recovery_manual', // Ulosotosta manuaalisena
  RECOVERY_IMPORT: 'recovery_import', // Ulosotosta sähköisesti
  DEBT_RESTRUCTURING: 'debt_restructuring', // Velkajärjestely
  HELGA_MANUAL: 'helga_manual', // Tuotu Helgasta
  WAREHOUSE_MANUAL: 'warehouse_manual', // Varikolta manuaalisesti
};

export const PaymentTypeReturnTypes = [PaymentType.RETURN_TO_ACCOUNT, PaymentType.RETURN_AS_MONEY_ORDER];

export const PaymentTypeAutomationTypes = [PaymentType.BANK_IMPORT, PaymentType.RECOVERY_IMPORT];

export const PaymentTypeOrder = [
  PaymentType.REGISTER_MANUAL,
  PaymentType.BANK_IMPORT,
  PaymentType.BANK_MANUAL,
  PaymentType.RETURN_TO_ACCOUNT,
  PaymentType.ARTIFICIAL_ADJUSTMENT,
  PaymentType.RETURN_AS_MONEY_ORDER,
  PaymentType.AMOUNT_CORRECTION,
  PaymentType.RECOVERY_MANUAL,
  PaymentType.RECOVERY_IMPORT,
  PaymentType.DEBT_RESTRUCTURING,
  PaymentType.HELGA_MANUAL,
  PaymentType.WAREHOUSE_MANUAL,
];

export const UploadStatus = {
  Pending: 'pending',
  Done: 'done',
  Failed: 'failed',
};

export const Permission = {
  Login: {
    WithPassword: 'LOGIN_WITH_PASSWORD',
    WithDevice: 'LOGIN_WITH_DEVICE',
  },
  VehicleInformation: {
    Read: 'READ_VEHICLE_INFORMATION',
  },
  ParkingPermit: {
    Read: 'READ_PARKING_PERMIT',
  },
  Claim: {
    Comment: 'COMMENT_CLAIM',
    Create: 'CREATE_CLAIM',
    Print: 'PRINT_CLAIM',
    Read: 'READ_CLAIM',
    ReadOwn: 'READ_OWN_CLAIM',
    Update: 'UPDATE_CLAIM',
    Delete: 'DELETE_CLAIM',
  },
  Device: {
    Create: 'CREATE_DEVICE',
    Read: 'READ_DEVICE',
    ReadOwn: 'READ_OWN_DEVICE',
    Update: 'UPDATE_DEVICE',
    Delete: 'DELETE_DEVICE',
  },
  GlobalSettings: {
    Read: 'READ_GLOBAL_SETTINGS',
    Update: 'UPDATE_GLOBAL_SETTINGS',
  },
  Payment: {
    Create: 'CREATE_PAYMENT',
    Read: 'READ_PAYMENT',
    Update: 'UPDATE_PAYMENT',
    Delete: 'DELETE_PAYMENT',
  },
  AddressPoint: {
    Create: 'CREATE_ADDRESS_POINT',
    Read: 'READ_ADDRESS_POINT',
    Update: 'UPDATE_ADDRESS_POINT',
    Delete: 'DELETE_ADDRESS_POINT',
  },
  PaymentArea: {
    Create: 'CREATE_PAYMENT_AREA',
    Read: 'READ_PAYMENT_AREA',
    Update: 'UPDATE_PAYMENT_AREA',
    Delete: 'DELETE_PAYMENT_AREA',
  },
  Report: {
    Create: 'CREATE_REPORT',
    Read: 'READ_REPORT',
    ReadOwn: 'READ_OWN_REPORT',
    Update: 'UPDATE_REPORT',
    UpdateOwn: 'UPDATE_OWN_REPORT',
    Delete: 'DELETE_REPORT',
    Export: 'EXPORT_REPORT',
  },
  ReportIdRange: {
    Create: 'CREATE_REPORT_ID_RANGE',
    Read: 'READ_REPORT_ID_RANGE',
  },
  Role: {
    Create: 'CREATE_ROLE',
    Read: 'READ_ROLE',
    Update: 'UPDATE_ROLE',
    Delete: 'DELETE_ROLE',
  },
  User: {
    Create: 'CREATE_USER',
    Read: 'READ_USER',
    ReadOwn: 'READ_OWN_USER',
    Update: 'UPDATE_USER',
    UpdateOwn: 'UPDATE_OWN_USER',
    Delete: 'DELETE_USER',
  },
  City: {
    Create: 'CREATE_CITY',
    Read: 'READ_CITY',
    Update: 'UPDATE_CITY',
    Delete: 'DELETE_CITY',
  },
  UsageReport: {
    Read: 'READ_USAGE_REPORT',
  },
  Customer: {
    Read: 'READ_CUSTOMER',
    ReadOwn: 'READ_OWN_CUSTOMER',
    Update: 'UPDATE_CUSTOMER',
    Delete: 'DELETE_CUSTOMER',
  },
  Recovery: {
    Read: 'READ_RECOVERY',
    Create: 'CREATE_RECOVERY',
    Delete: 'DELETE_RECOVERY',
  },
};

export const RecoveryRequestType = {
  APPLICATION: 'recovery_application',
  CANCELLATION: 'recovery_cancellation',
  RECOVERY_SUM_CHANGE: 'recovery_sum_change',
  ENFORCEMENT_TYPE_CHANGE: 'enforcement_type_change',
};

export const EnforcementType = {
  NORMAL: 'normal',
  LIMITED: 'limited',
};

export const WaitingForComment = {
  NO: 'not_waiting_for_comment',
  INSPECTOR: 'waiting_for_inspector_comment',
  OFFICE_USER: 'waiting_for_office_user_comment',
};

export const WaitingForCommentOrder = [
  WaitingForComment.NO,
  WaitingForComment.INSPECTOR,
  WaitingForComment.OFFICE_USER,
];

export const InvoiceType = {
  MANUAL_INVOICE: 'manual_invoice',
  MANUAL_PAYMENT_DEMAND: 'manual_payment_demand',
  ROPO_PAYMENT_DEMAND: 'ropo_payment_demand',
};

export const PaymentDemandInvoiceTypes = [InvoiceType.MANUAL_PAYMENT_DEMAND, InvoiceType.ROPO_PAYMENT_DEMAND];

export const IncapabilityTypes = {
  LACK_OF_MEANS: 'lack_of_means', // 1. Varattomuuseste
  LACK_OF_MEANS_AND_UNKNOWN: 'lack_of_means_and_unknown', // 2. Varattomuus- ja tuntemattomuuseste
  OTHER: 'other', // 3. Muu este
  LIMITED_ENFORCEMENT: 'limited_enforcement', // 4. Suppean ulosoton este
  LIMITED_ENFORCEMENT_AND_UNKNOWN: 'limited_enforcement_and_unknown', // 5. Suppean ulosoton este ja tuntemattomuuseste
};

export const UljasCodeIncapabilityType = {
  1: IncapabilityTypes.LACK_OF_MEANS,
  2: IncapabilityTypes.LACK_OF_MEANS_AND_UNKNOWN,
  3: IncapabilityTypes.OTHER,
  4: IncapabilityTypes.LIMITED_ENFORCEMENT,
  5: IncapabilityTypes.LIMITED_ENFORCEMENT_AND_UNKNOWN,
};

export const LanguageCodes = ['fi', 'sv', 'en'];

export const RecoveryCancellationType = {
  PAID: 'paid',
  OTHER: 'other',
};

export const RecoverySumChangeType = {
  PAID: 'paid',
  OTHER: 'other',
  RECEIVABLE_CHANGE: 'receivable_change',
};

export const FinishReasons = {
  PAYMENT_LINKED: 'B_PAYMENT_LINKED_STATE_TO_FINISHED',
  FOREIGN: 'B_REPORT_FOREIGN_STATE_TO_FINISHED',
  DIPLOMAT: 'B_REPORT_DIPLOMAT_STATE_TO_FINISHED',
  CLAIM_DECISION: 'B_CLAIM_DECISION_FINISHED_REPORT',
};

export const PassiveReasons = {
  CLAIM: 'B_CLAIM_MADE_REPORT_PASSIVE',
  CLAIM_IN_COURT: 'B_CLAIM_IN_COURT_MADE_REPORT_PASSIVE',
};

export const UnclearReasons = {
  RECOVERY: 'B_UNCLEAR_REASON_RECOVERY',
  CLAIM: 'B_UNCLEAR_REASON_CLAIM',
  PAYMENT: 'B_UNCLEAR_REASON_PAYMENT',
  PAYMENT_DEMAND: 'B_UNCLEAR_REASON_PAYMENT_DEMAND',
  IMPEDIMENT_CERTIFICATE: 'B_UNCLEAR_REASON_IMPEDIMENT_CERTIFICATE',
  CUSTOMER_INFORMATION: 'B_UNCLEAR_REASON_CUSTOMER_INFORMATION',
  RUNAWAY: 'B_UNCLEAR_REASON_RUNAWAY',
  OTHER: 'B_UNCLEAR_REASON_OTHER',
};

export const ReceivableRaiseCalculationDayOptions = {
  RAISE_TIME: 'RAISE_TIME',
  DEMAND_SEND_DATE: 'DEMAND_SEND_DATE',
};

export const postcodePattern = '^$|^[0-9]{5}$';
export const postcodeInCityFieldRegex = /\d{3}/;

export const emailPattern = '^$|^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$';
export const phoneNumberPattern = '^$|^\\+?(?:\\s?[0-9]){6,14}[0-9]$';

export const personIdentifierPattern = '[0-3]{1}[0-9]{1}[0-1]{1}[0-9]{1}[0-9]{2}[+-A]{1}[0-9]{3}[0-Z]{1}';
export const companyIdentifierPattern = '[0-9]{7}[-]{1}[0-9]{1}';
export const customerIdentifierPattern = `${personIdentifierPattern}|${companyIdentifierPattern}`;

export const ChangeType = {
  CHANGE: 'CHANGE',
  ACCESS: 'ACCESS',
};

export const SerialUnknown = 'unknown';
